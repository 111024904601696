<template>
  <dashboard-page-wrapper title="Account">
    <div class="d-flex flex-column account-page">
      <b-row>
        <b-col lg="7">
          <card :data-id="`${userId}`" bodyClasses="p-0" class="bg-secondary">
            <template slot="header">
              <h3 class="mb-0">Your info</h3>
            </template>
            <ValidationObserver ref="formValidator" v-slot="{ invalid, validate }">
              <b-form id="edit-user-form" class="mt-4" @submit.prevent="validate().then(updateProfile)">
                <div class="px-4">
                  <b-row>
                    <b-col lg="6">
                      <base-input v-model="user.firstName" group label="First name" name="First name"
                                  placeholder="First name" rules="required|min:3" type="text">
                      </base-input>
                    </b-col>
                    <b-col lg="6">
                      <base-input v-model="user.lastName" group label="Last name" name="Last name"
                                  placeholder="Last name" rules="required|min:1" type="text">
                      </base-input>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col lg="6">
                      <base-input :value="user.email" disabled group label="Email address"
                                  name="Email" placeholder="Enter email" rules="required|email" type="email">
                        <template #append v-if="emailVerificationEnabled && !userEmailVerified">
                          <el-tooltip content="Email is not verified. Please verify."
                                      effect="dark" placement="top-start">
                            <i class="fas fa-exclamation-triangle text-warning"></i>
                          </el-tooltip>
                        </template>
                      </base-input>
                      <div v-if="emailVerificationEnabled && !userEmailVerified" class="small-text float-right" style="margin-top: -30px">
                        <a class="text-center d-inline-block font-600 pre-small-text" @click="resendVerificationEmail">
                          Resend verification email</a>
                      </div>
                    </b-col>
                    <template v-if="showAnalytics">
                      <b-col class="mb-4" lg="6">
                        <analytics/>
                      </b-col>
                    </template>
                    <template v-if="showCallForwarding">
                      <b-col class="mt-2" xl="6">
                        <div class="d-flex">
                          <base-input v-model="user.callForwardingNumber" :disabled="!user.callForwardingEnabled"
                                      :required="user.callForwardingEnabled" class="flex-fill"
                                      group label="Call forwarding number"
                                      name="Call forwarding number"
                                      placeholder="" rules="phoneNumber"
                                      type="phone" @input="acceptNumber">
                          </base-input>
                          <base-switch v-model="user.callForwardingEnabled" class="phone-switcher ml-3" offText="Off"
                                       onText="On" @input="togglePhone">
                          </base-switch>
                        </div>
                      </b-col>
                    </template>
                    <b-col v-if="seatRole !== seatRoles.HIRING_MANAGER" xl="6">
                      <messageScheduling :userTimezone="userTimezone" @onchange="setTimeZone"/>
                    </b-col>
                    <template v-if="!twilioNumberFormatted && seatRole !== seatRoles.HIRING_MANAGER">
                      <b-col class="mt-3" xl="6">
                        <div class="d-flex align-items-center h-100 pb-3">
                          <base-button type="primary" @click="$router.push({ name: 'Get Number' })">
                            <i class="ni ni-fat-add"></i>Get a number
                          </base-button>
                        </div>
                      </b-col>
                    </template>
                  </b-row>
                </div>
                <hr class="my-4"/>
                <div class="d-flex justify-content-end px-4 mb-4">
                  <button :disabled="invalid || btnDisabled" class="btn btn-primary" form="edit-user-form"
                          type="submit">Save
                  </button>
                </div>
              </b-form>
            </ValidationObserver>
          </card>
        </b-col>
        <b-col v-if="this.seatRole !== seatRoles.HIRING_MANAGER" lg="5">
          <b-row v-if="accountType === accountTypes.TEAM">
            <b-col md="12">
              <card bodyClasses="p-3 pb-3" class="bg-secondary">
                <template slot="header">
                  <h3 class="mb-0">Out of Office</h3>
                </template>
                <base-switch v-model="enableOutOfOffice" offText="Off" onText="On" @input="toggleOutOfOffice">
                </base-switch>
                <div>
                  <b-row class="mt-3">
                    <b-col class="mb-3" md="12">
                      When turned on, all incoming messages will receive this automated reply.
                      <br><br>
                      You will need to refresh your page for the extension to update.
                    </b-col>
                    <b-col md="12">
                      <out-of-office-form :outOfOfficeMessage="outOfOfficeMessage"
                                          :outOfOfficeMessageEnabled="enableOutOfOffice"/>
                    </b-col>
                  </b-row>
                </div>
              </card>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <div v-if="userType === userTypes.ADMIN" class="cancellation-block pre-md-text">
        <!--        Looking to delete your account or cancel your plan?
        <b @click="showCancellationModal" class="active-element"><u>Start-->
        <!--        here</u></b>.-->
        Looking to delete your account or cancel your plan? <u>Contact support</u>.
      </div>
    </div>
    <invite-myself-modal ref="inviteMyselfModal"></invite-myself-modal>
    <cancellation-modal ref="cancellationModal"/>
    <confirmation-modal ref="phoneBuyModal" :isClose="true"
                        :showCancel="false" btnClass="justify-content-end" confirmBtnText="Ok"
                        header="1 hour until you can text"
                        question="You’ll need to wait 1 hour for this new number to be fully registered
        with the telco carriers before using." @on-accept="updateBuyPhoneState" @on-close="updateBuyPhoneState"/>
    <number-linking-modal
      ref="numberLinkingModal"
    />
  </dashboard-page-wrapper>
</template>
<script>
import {mapActions, mapGetters} from 'vuex';
import {
  SEND_VERIFICATION_EMAIL,
  UPDATE_ACCOUNT_REQUEST,
  UPDATE_CALL_FORWARDING_REQUEST,
  UPDATE_OUT_OF_OFFICE_MSG_REQUEST,
  UPDATE_USER_REQUEST,
} from '@/store/storeActions';
import DashboardPageWrapper from '@/components/App/UI/DashboardPageWrapper.vue';
import {accountTypes, seatRoles, userTypes} from '@/store/userConstants';
import InviteMyselfModal from '@/views/Dashboard/team/InviteMyselfModal.vue';
import CancellationModal from './cancellationModal/CancellationModal.vue';
import Analytics from './analytics/Analytics.vue';
import MessageScheduling from './scheduling/MessageScheduling.vue';
import ConfirmationModal from '@/components/App/modals/ConfirmationModal.vue';
import OutOfOfficeForm from '../automations/OutOfOfficeForm.vue';
import NumberLinkingModal from '@/components/App/modals/NumberLinkingModal.vue';

export default {
  components: {
    DashboardPageWrapper,
    InviteMyselfModal,
    CancellationModal,
    Analytics,
    MessageScheduling,
    ConfirmationModal,
    OutOfOfficeForm,
    NumberLinkingModal,
  },
  data() {
    return {
      user: {
        company: '',
        email: '',
        firstName: '',
        lastName: '',
        callForwardingEnabled: false,
        callForwardingNumber: '',
        timezone: '',
      },
      accountTypes,
      userTypes,
      userLoaded: false,
      userInfoChanged: false,
      accountInfoChanged: false,
      callForwardingChanged: false,
      isNewBuyPhoneNumber: false,
      enableOutOfOffice: false,
      emailVerificationEnabled: false,
      seatRoles,
    };
  },
  computed: {
    ...mapGetters([
      'userId',
      'userFirstName',
      'userLastName',
      'userEmail',
      'userEmailVerified',
      'userCompany',
      'userPhoneNumber',
      'userTimezone',
      'accountType',
      'userType',
      'planType',
      'twilioNumberFormatted',
      'callForwardingEnabled',
      'callForwardingNumber',
      'isPaygPlan',
      'totalSeats',
      'availableSeats',
      'isBuyPhoneNumber',
      'outOfOfficeMessageEnabled',
      'outOfOfficeMessage',
      'seatRole',
      'featuresEnabled',
      'isLinkingNumber',
    ]),
    btnDisabled: function() {
      return !(this.userInfoChanged || this.accountInfoChanged || this.callForwardingChanged);
    },
    showAnalytics: function() {
      return !this.isPaygPlan && this.twilioNumberFormatted && this.seatRole === seatRoles.RECRUITER;
    },
    showCallForwarding: function() {
      return !this.isPaygPlan && this.twilioNumberFormatted && this.seatRole === seatRoles.RECRUITER;
    },
  },
  watch: {
    user: {
      deep: true,
      handler: function(user) {
        this.userInfoChanged = (this.userFirstName !== user.firstName || this.userLastName !== user.lastName ||
            (this.userTimezone !== user.timezone && user.timezone !== undefined));
        this.callForwardingChanged = (
            (this.callForwardingNumber !== user.callForwardingNumber && user.callForwardingNumber !== '') ||
            this.callForwardingEnabled !== user.callForwardingEnabled
        );
        this.accountInfoChanged = this.userCompany !== user.company;
      },
    },
    isBuyPhoneNumber: {
      handler: function(value) {
        this.isNewBuyPhoneNumber = value;
      },
      immediate: true,
    },
    outOfOfficeMessageEnabled: {
      handler: function(value) {
        this.enableOutOfOffice = value;
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions([
      SEND_VERIFICATION_EMAIL,
      UPDATE_USER_REQUEST,
      UPDATE_ACCOUNT_REQUEST,
      UPDATE_CALL_FORWARDING_REQUEST,
      UPDATE_OUT_OF_OFFICE_MSG_REQUEST,
    ]),
    togglePhone(value) {
      !value && (this.user.callForwardingNumber = '');
    },
    async updateProfile() {
      try {
        if (this.userInfoChanged) {
          const payLoad = {
            firstName: this.user.firstName,
            lastName: this.user.lastName,
            timezone: this.user.timezone,
          };
          await this.UPDATE_USER_REQUEST(payLoad);
        }
        if (this.accountInfoChanged) {
          await this.UPDATE_ACCOUNT_REQUEST({
            name: this.user.company,
          });
        }
        if (this.callForwardingChanged) {
          await this.UPDATE_CALL_FORWARDING_REQUEST({
            callForwardingNumber: this.user.callForwardingNumber,
            callForwardingEnabled: this.user.callForwardingEnabled,
          });
        }
      } finally {
        this.userLoaded = false;
        this.setDataFromStore();
      }
    },
    getCallForwardingData() {
      if (!this.user.callForwardingEnabled) {
        return {
          callForwardingEnabled: this.user.callForwardingEnabled,
        };
      } else {
        return {
          callForwardingEnabled: this.user.callForwardingEnabled,
          callForwardingNumber: this.user.callForwardingNumber,
        };
      }
    },
    setDataFromStore() {
      if (!!this.userId && !this.userLoaded) {
        this.user.firstName = this.userFirstName;
        this.user.lastName = this.userLastName;
        this.user.email = this.userEmail;
        this.user.company = this.userCompany;
        this.user.callForwardingEnabled = this.callForwardingEnabled;
        this.user.callForwardingNumber = this.callForwardingNumber;
        this.userInfoChanged = false;
        this.accountInfoChanged = false;
        this.callForwardingChanged = false;
        this.userLoaded = true;
        this.user.timezone = this.userTimezone;
      }
    },
    acceptNumber() {
      const x = this.user.callForwardingNumber.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      this.user.callForwardingNumber = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
    },
    showInvitationModal: function() {
      this.$refs.inviteMyselfModal.displayToggle(true);
    },
    showCancellationModal: function() {
      this.$refs.cancellationModal.displayToggle(true);
    },
    setTimeZone(timezone) {
      this.user.timezone = timezone;
    },
    updateBuyPhoneState() {
      this.$store.commit('BUY_PHONE_NUMBER_SUCCESS', {isBuyPhoneNumber: false});
    },
    toggleOutOfOffice: function(value) {
      const payload = {
        outOfOfficeMessageEnabled: this.enableOutOfOffice,
        userId: this.userId,
      };
      this.UPDATE_OUT_OF_OFFICE_MSG_REQUEST({payload});
      this.enableOutOfOffice = value;
      this.$store.state.auth.user.outOfOfficeMessageEnabled = value;
    },
    resendVerificationEmail: async function() {
      this.captchaToken = await this.executeRecaptcha();
      const resetEmailData = {
        email: this.userEmail,
        captchaToken: this.captchaToken,
      };
      this.SEND_VERIFICATION_EMAIL(resetEmailData);
    },
    // Executes Google reCAPTCHA verification for added security
    executeRecaptcha() {
      return new Promise((resolve, reject) => {
        grecaptcha.ready(() => {
          grecaptcha.execute(process.env.VUE_APP_GOOGLE_CAPTCHA_KEY, {action: 'email_verification'}).
              then(resolve).
              catch(reject);
        });
      });
    },
  },
  created: function() {
    this.setDataFromStore();
  },
  beforeUpdate: function() {
    this.setDataFromStore();
  },
  mounted: function() {
    if (this.isNewBuyPhoneNumber) this.$refs.phoneBuyModal.openModal();
    if (this.featuresEnabled && this.featuresEnabled.includes('email_verification')) {
      this.emailVerificationEnabled = true;
    }

    if (this.isLinkingNumber) this.$refs.numberLinkingModal.openModal();
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/sass/custom/_app-vars.scss";

.profile-header {
  background-image: url(/img/theme/profile-cover.jpg);
  background-size: cover;
  background-position: center top;
  min-height: 500px;
}

.account-page {
  min-height: calc(100vh - 120px);
}

.phone-switcher {
  margin-top: 43px;
}

.cancellation-block {
  color: $gray-600;
}
</style>
